// Hooks Nuxt error handler. Will receive any errors thrown with `throw createError(error)`
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, context) => {
    console.warn('~ wetix error handler ~ - ', { error, context })

    // Let the user know
    useToast().add({
      title: 'Error',
      description: `${error}`,
      color: 'red',
      timeout: 0, // DO NOT auto-timeout these, as it will cause errors to re-occur
      icon: 'i-heroicons-bug-ant-20-solid',
      // callback: () => {
      //   console.info('~ wetix error handler ~ - clearing error')
      //   clearError()
      // },
      actions: [{
        label: 'Clear',
        click: () => {
          console.info('~ wetix error handler ~ - clearing error')
          clearError()
        }
      }]
    })
  }
})
