export default defineNuxtPlugin(() => {
  return {
    provide: {
      // Returns URL for cloudflare images hosted image.
      cloudflareImmages: (imageId: string, variantName: string | null) => {
        return `https://wetix.net/cdn-cgi/imagedelivery/kCXBEBYe-fVFQQ06UGmg1w/${imageId}/${variantName}`
      }
    }
  }
})
