import { useAuthState } from '~/stores/auth'
// import { useAuth } from '@vueuse/firebase/useAuth'

export default defineNuxtRouteMiddleware((to, _from) => {
  // Get auth state
  const auth = useAuthState()
  const nuxtApp = useNuxtApp()
  const fbAuth = useFirebaseAuth()
  const { ssrContext } = nuxtApp

  // console.log(`#### AUTH CHECK firebaseAuth: `, $firebaseAuth)

  // console.log(`middleware/auth.global - middleware running ${useNuxtApp().ssrContext ? 'server side' : 'client side'}`)

  // STOP IF IN SSR CONTEXT - As we don't have firebase SDK available in SSR (client only at this time)
  if (ssrContext) {
    // console.log(`Gloabl auth check middleware running in SSR. Skipping..`)
    //  console.log(`middleware/auth.global - Gloabl auth check middleware running in SSR. Skipping..`)
    //  console.log(`middleware/auth.global - Not doing anything..`)
    return
  }

  // const currentAuthUser = $getCurrentAuthUser()
  // console.log({ currentAuthUser })

  // console.log(`middleware/auth.global - auth.userIsTenantAdmin is: ${auth.userIsTenantAdmin}`, { ssrContext, auth, userIsTenantAdmin: `${auth.userIsTenantAdmin}` })

  // Check if the 'to' route containts 'admin' and if so, redirect to login.
  if (to?.name?.toString().includes('admin') && !auth.userIsTenantAdmin && !ssrContext) {
    // console.log(`middleware/auth.global - info...`, { ssrContext, auth, currentAuthUser })

    // console.log('middleware/auth.global - checking if user is tenant admin', { auth, fbAuth, ssrContext, to, from })

    // If firebase auth hasn't finished initialising yet, we won't do anything.
    if (!fbAuth) {
      // console.error('middleware/auth.global - Firebase auth is NOT available hasn\'t finished initialising yet. Skipping..')
      return
    } else {
      // console.log('middleware/auth.global - Firebase auth IS available.')
    }

    // Check is the user is a tenantadmin
    // const idTokenResult = $firebaseAuth.currentUser.getIdTokenResult()
    // console.log(`middleware/auth.global - ID token`, { idTokenResult })

    // console.warn(`middleware/auth.global - Attempted to navigate to admin route but user is not admin. Redirecting to login.`)
    // if ($notify) {
    //     console.log(`middleware/auth.global - Notifying user of unauthorised route before navigating to login page..`)
    //     $notify({ timeout: 10000, title: `Unauthorised`, message: 'You are not authorized to access that page.' })
    // }

    if (auth.currentUser && !auth.userIsTenantAdmin) {
      return navigateTo('/login')
    }

    return
    // TODO: Refactor auth!
    // return navigateTo('/login')
  }

  if (!auth.isAuthed) {
    // console.log(`middleware/auth.global - !auth.isAuthed - User not authed.`)
    return null // Do nothing
  }

  // Otherwise allow the navigation
  // console.log(`middleware/auth.global - No matched rules. Proceeding with navigation.`)
})
