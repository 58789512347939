import { default as index5RgnqYXR34Meta } from "/vercel/path0/apps/dash/pages/account/index.vue?macro=true";
import { default as indexFU3rJUrZRFMeta } from "/vercel/path0/apps/dash/pages/account/settings/index.vue?macro=true";
import { default as guestlistKvERGp0Sj5Meta } from "/vercel/path0/apps/dash/pages/app/access-control/guestlist.vue?macro=true";
import { default as history48ccwjsLjPMeta } from "/vercel/path0/apps/dash/pages/app/access-control/history.vue?macro=true";
import { default as indexeLs8BxWN4NMeta } from "/vercel/path0/apps/dash/pages/app/access-control/index.vue?macro=true";
import { default as scanR8hCqLLOJSMeta } from "/vercel/path0/apps/dash/pages/app/access-control/scan.vue?macro=true";
import { default as settingsSDt3rbaqJZMeta } from "/vercel/path0/apps/dash/pages/app/access-control/settings.vue?macro=true";
import { default as accreditation4wYCxUl8ckMeta } from "/vercel/path0/apps/dash/pages/app/access-control/view/accreditation.vue?macro=true";
import { default as customertphSfGRpMUMeta } from "/vercel/path0/apps/dash/pages/app/access-control/view/customer.vue?macro=true";
import { default as orderGXhLm4snXLMeta } from "/vercel/path0/apps/dash/pages/app/access-control/view/order.vue?macro=true";
import { default as thirdparty_45ticketPg69eRgZgcMeta } from "/vercel/path0/apps/dash/pages/app/access-control/view/thirdparty-ticket.vue?macro=true";
import { default as ticketPA8dxHEhF6Meta } from "/vercel/path0/apps/dash/pages/app/access-control/view/ticket.vue?macro=true";
import { default as indexKKkbSHq77aMeta } from "/vercel/path0/apps/dash/pages/app/communication/audiences/index.vue?macro=true";
import { default as indexy3Tj3GJF87Meta } from "/vercel/path0/apps/dash/pages/app/communication/build/email/index.vue?macro=true";
import { default as _91campaignId_93FUcsGHOOucMeta } from "/vercel/path0/apps/dash/pages/app/communication/campaigns/[campaignId].vue?macro=true";
import { default as createlR7qBMgcGQMeta } from "/vercel/path0/apps/dash/pages/app/communication/campaigns/create.vue?macro=true";
import { default as indexGpCaRki8enMeta } from "/vercel/path0/apps/dash/pages/app/communication/campaigns/index.vue?macro=true";
import { default as indexfia3J9MHafMeta } from "/vercel/path0/apps/dash/pages/app/communication/index.vue?macro=true";
import { default as _91id_93OCTdMjcRLSMeta } from "/vercel/path0/apps/dash/pages/app/communication/lists/[id].vue?macro=true";
import { default as indexl9Db6ZZ1mKMeta } from "/vercel/path0/apps/dash/pages/app/communication/lists/index.vue?macro=true";
import { default as indexOvSdi2S4TAMeta } from "/vercel/path0/apps/dash/pages/app/communication/media/index.vue?macro=true";
import { default as indexuq3WZVsMIXMeta } from "/vercel/path0/apps/dash/pages/app/communication/templates/index.vue?macro=true";
import { default as indexxhaEqjd5rzMeta } from "/vercel/path0/apps/dash/pages/app/index.vue?macro=true";
import { default as indexdsNNEQ4IB7Meta } from "/vercel/path0/apps/dash/pages/app/promote/index.vue?macro=true";
import { default as indexN8A5fFkOd4Meta } from "/vercel/path0/apps/dash/pages/app/reports/index.vue?macro=true";
import { default as _91id_93N953rUH25DMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/coupons/[id].vue?macro=true";
import { default as indexxxQQY9xER5Meta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/coupons/index.vue?macro=true";
import { default as create_45new_45event1ScBTnNrAfMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/create-new-event.vue?macro=true";
import { default as _91id_93ONopOPqhuwMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/customers/[id].vue?macro=true";
import { default as indexPxLB7CUqq8Meta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/customers/index.vue?macro=true";
import { default as analyticsmDq2oEVOIyMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/analytics.vue?macro=true";
import { default as _91broadcastId_93s6948yRjjVMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/broadcasts/[broadcastId].vue?macro=true";
import { default as indexc7IAMLciDsMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/broadcasts/index.vue?macro=true";
import { default as detailsVCdBLpqkEuMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/details.vue?macro=true";
import { default as doorlistN0CPYeSDARMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/doorlist.vue?macro=true";
import { default as duplicateJ6gNrVAvhEMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/duplicate.vue?macro=true";
import { default as guest_45listpuHplvkuKuMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/guest-list.vue?macro=true";
import { default as import_45scanned_45ticketsdMXULpOFFwMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/import-scanned-tickets.vue?macro=true";
import { default as import_45tickets0HRV0SLzvWMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/import-tickets.vue?macro=true";
import { default as imported_45tickets7VWL6WJUcpMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/imported-tickets.vue?macro=true";
import { default as indexztEpJGk8BgMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/index.vue?macro=true";
import { default as integrationsol3CvG5TbiMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/integrations.vue?macro=true";
import { default as issued_45ticketsgv9feVciSPMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/issued-tickets.vue?macro=true";
import { default as liveHc3YiqYUUKMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/live.vue?macro=true";
import { default as sales_45reporty3aY7RnGZEMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/sales-report.vue?macro=true";
import { default as settingsPt9nD0OSzDMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/settings.vue?macro=true";
import { default as shop_45settingsTvtxD4XV2bMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/shop-settings.vue?macro=true";
import { default as ticket_45typestBB5xE8slnMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/ticket-types.vue?macro=true";
import { default as toolsLZf9aiuv0nMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/tools.vue?macro=true";
import { default as waitlist_45signupsliQnkTJZ1dMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/waitlist-signups.vue?macro=true";
import { default as _91id_93qzz0FzVIF7Meta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id].vue?macro=true";
import { default as indexjLnA9Rscm4Meta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/events/index.vue?macro=true";
import { default as helpEiW5VKKH0eMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/help.vue?macro=true";
import { default as indexLBlEcNKfxNMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/index.vue?macro=true";
import { default as _91eventId_93fdAGNb6kN9Meta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/integrations/[integrationId]/[eventId].vue?macro=true";
import { default as indexWSlLyQZFhiMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/integrations/[integrationId]/index.vue?macro=true";
import { default as create6QBsMlqstnMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/integrations/create.vue?macro=true";
import { default as indexhT5jvYgvK2Meta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/integrations/index.vue?macro=true";
import { default as _91id_93uioUTUwfdoMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/invoices/[id].vue?macro=true";
import { default as indexerBEk1BlTwMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/invoices/index.vue?macro=true";
import { default as logoutRdaI3cxg9uMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/logout.vue?macro=true";
import { default as messagesuOurZj0lbTMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/messages.vue?macro=true";
import { default as _91id_93ICa2NKrBaGMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/more/campaigns/[id].vue?macro=true";
import { default as communication5qDIcw02eHMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/more/communication.vue?macro=true";
import { default as indexP2BC9ftzSqMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/more/emails/index.vue?macro=true";
import { default as indexLRBt0wdyTpMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/more/image-library/index.vue?macro=true";
import { default as _91id_93aRplLMW59PMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/more/mailing-lists/[id].vue?macro=true";
import { default as morep902k2C98mMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/more.vue?macro=true";
import { default as indexysQd51AiHyMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/order-edit/[orderid]/index.vue?macro=true";
import { default as indexu3CwzE0AO9Meta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/order-edit/index.vue?macro=true";
import { default as _91orderid_93etjKc72qW3Meta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/orders/[orderid].vue?macro=true";
import { default as indexdVMk3lhD9uMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/orders/create/index.vue?macro=true";
import { default as indexntUWATeXGwMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/orders/index.vue?macro=true";
import { default as indexOCYgL95iFIMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/orders/search/index.vue?macro=true";
import { default as _91orderId_93xkfyB84aJ1Meta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/orders/third-party-order/[orderId].vue?macro=true";
import { default as index84SKmldNR5Meta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/scanners/index.vue?macro=true";
import { default as settingsqngMOT43eLMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/settings.vue?macro=true";
import { default as reauthWHGhTGGzggMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/stripe-connect/reauth.vue?macro=true";
import { default as returnKF7j8QdZoxMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/stripe-connect/return.vue?macro=true";
import { default as indexQvX3mgyWdEMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/tickets/index.vue?macro=true";
import { default as transactionsYwQioAOuRxMeta } from "/vercel/path0/apps/dash/pages/app/tenant-admin/transactions.vue?macro=true";
import { default as helpJpC16QVQwLMeta } from "/vercel/path0/apps/dash/pages/app/user/help.vue?macro=true";
import { default as indexBZMaIpqAdwMeta } from "/vercel/path0/apps/dash/pages/app/user/index.vue?macro=true";
import { default as orderss56eEpYEUJMeta } from "/vercel/path0/apps/dash/pages/app/user/orders.vue?macro=true";
import { default as payment_45methodskJPe1DhhMbMeta } from "/vercel/path0/apps/dash/pages/app/user/payment-methods.vue?macro=true";
import { default as plansyyAcNiPG1IMeta } from "/vercel/path0/apps/dash/pages/app/user/plans.vue?macro=true";
import { default as billingEeRNKTaegEMeta } from "/vercel/path0/apps/dash/pages/app/user/profile/billing.vue?macro=true";
import { default as general3MEgyDAOGjMeta } from "/vercel/path0/apps/dash/pages/app/user/profile/general.vue?macro=true";
import { default as passwordXVI39BXEIjMeta } from "/vercel/path0/apps/dash/pages/app/user/profile/password.vue?macro=true";
import { default as profilejPgxkH4QzKMeta } from "/vercel/path0/apps/dash/pages/app/user/profile.vue?macro=true";
import { default as share3U7msZOK95Meta } from "/vercel/path0/apps/dash/pages/app/user/share.vue?macro=true";
import { default as sign_45outXdlT7Ee0tkMeta } from "/vercel/path0/apps/dash/pages/app/user/sign-out.vue?macro=true";
import { default as active_45tenantpcoKggrcXvMeta } from "/vercel/path0/apps/dash/pages/dev/active-tenant.vue?macro=true";
import { default as alt_45account_45home4XDwgMyfkNMeta } from "/vercel/path0/apps/dash/pages/dev/alt-account-home.vue?macro=true";
import { default as confign4xdA2GreyMeta } from "/vercel/path0/apps/dash/pages/dev/config.vue?macro=true";
import { default as errortestCmjHXk5Xx8Meta } from "/vercel/path0/apps/dash/pages/dev/errortest.vue?macro=true";
import { default as indexwJRNHwFfL9Meta } from "/vercel/path0/apps/dash/pages/dev/index.vue?macro=true";
import { default as layout_452IBTzBXAHMaMeta } from "/vercel/path0/apps/dash/pages/dev/layout-2.vue?macro=true";
import { default as sidebarfe6htQ7LS3Meta } from "/vercel/path0/apps/dash/pages/dev/sidebar.vue?macro=true";
import { default as ssrko3cFwPiAZMeta } from "/vercel/path0/apps/dash/pages/dev/ssr.vue?macro=true";
import { default as indexLkXUKCmPncMeta } from "/vercel/path0/apps/dash/pages/download-tickets/index.vue?macro=true";
import { default as homeOB00DrN7vTMeta } from "/vercel/path0/apps/dash/pages/home.vue?macro=true";
import { default as indexqed5IaIOjBMeta } from "/vercel/path0/apps/dash/pages/index.vue?macro=true";
import { default as landingWqMjQDtlQjMeta } from "/vercel/path0/apps/dash/pages/landing.vue?macro=true";
import { default as codeoKYoam8tnPMeta } from "/vercel/path0/apps/dash/pages/login/code.vue?macro=true";
import { default as email_45passwordUwrz4ieBqEMeta } from "/vercel/path0/apps/dash/pages/login/email-password.vue?macro=true";
import { default as index8RaOjk7WdkMeta } from "/vercel/path0/apps/dash/pages/login/index.vue?macro=true";
import { default as welcomeZiDFD3bVhLMeta } from "/vercel/path0/apps/dash/pages/login/welcome.vue?macro=true";
import { default as complete_45onboarding9BAaIqbaqjMeta } from "/vercel/path0/apps/dash/pages/onboarding/complete-onboarding.vue?macro=true";
import { default as indexv2BIrxWVxWMeta } from "/vercel/path0/apps/dash/pages/onboarding/index.vue?macro=true";
import { default as pricingRv7XcT3PydMeta } from "/vercel/path0/apps/dash/pages/pricing.vue?macro=true";
import { default as quickv5mEj5VdU8Meta } from "/vercel/path0/apps/dash/pages/quick.vue?macro=true";
import { default as registerzqPmLbGQcfMeta } from "/vercel/path0/apps/dash/pages/register.vue?macro=true";
import { default as indexxgmBQCblvoMeta } from "/vercel/path0/apps/dash/pages/scan/code/index.vue?macro=true";
import { default as lookup5T5zEVFa88Meta } from "/vercel/path0/apps/dash/pages/scan/code/lookup.vue?macro=true";
import { default as index_45oldXgTjNeBWfMMeta } from "/vercel/path0/apps/dash/pages/scan/index-old.vue?macro=true";
import { default as indexRycQBdVLNrMeta } from "/vercel/path0/apps/dash/pages/scan/index.vue?macro=true";
import { default as sign_45up_45landing7UdZ7g9PtpMeta } from "/vercel/path0/apps/dash/pages/sign-up-landing.vue?macro=true";
import { default as sign_45up_45old50MVA6siDhMeta } from "/vercel/path0/apps/dash/pages/sign-up-old.vue?macro=true";
import { default as sign_45upnkbH2bO3TLMeta } from "/vercel/path0/apps/dash/pages/sign-up.vue?macro=true";
import { default as signedout26UpwF1D9BMeta } from "/vercel/path0/apps/dash/pages/signedout.vue?macro=true";
import { default as solutions4m8l3A6tcoMeta } from "/vercel/path0/apps/dash/pages/solutions.vue?macro=true";
import { default as indexuZ4qYiWmW5Meta } from "/vercel/path0/apps/dash/pages/speech/index.vue?macro=true";
import { default as indexvuhyLJtivEMeta } from "/vercel/path0/apps/dash/pages/support/index.vue?macro=true";
import { default as indexSgE4180AZJMeta } from "/vercel/path0/apps/dash/pages/test/index.vue?macro=true";
import { default as landing1XTHTMnKNeUMeta } from "/vercel/path0/apps/dash/pages/test/landing1.vue?macro=true";
import { default as landing2uQxiUaoSS6Meta } from "/vercel/path0/apps/dash/pages/test/landing2.vue?macro=true";
import { default as indexCIdkAVQRxHMeta } from "/vercel/path0/apps/dash/pages/tickets/index.vue?macro=true";
import { default as testDSw6lsjKQSMeta } from "/vercel/path0/apps/dash/pages/tickets/test.vue?macro=true";
export default [
  {
    name: index5RgnqYXR34Meta?.name ?? "account",
    path: index5RgnqYXR34Meta?.path ?? "/account",
    meta: index5RgnqYXR34Meta || {},
    alias: index5RgnqYXR34Meta?.alias || [],
    redirect: index5RgnqYXR34Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexFU3rJUrZRFMeta?.name ?? "account-settings",
    path: indexFU3rJUrZRFMeta?.path ?? "/account/settings",
    meta: indexFU3rJUrZRFMeta || {},
    alias: indexFU3rJUrZRFMeta?.alias || [],
    redirect: indexFU3rJUrZRFMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/account/settings/index.vue").then(m => m.default || m)
  },
  {
    name: guestlistKvERGp0Sj5Meta?.name ?? "app-access-control-guestlist",
    path: guestlistKvERGp0Sj5Meta?.path ?? "/app/access-control/guestlist",
    meta: guestlistKvERGp0Sj5Meta || {},
    alias: guestlistKvERGp0Sj5Meta?.alias || [],
    redirect: guestlistKvERGp0Sj5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/access-control/guestlist.vue").then(m => m.default || m)
  },
  {
    name: history48ccwjsLjPMeta?.name ?? "app-access-control-history",
    path: history48ccwjsLjPMeta?.path ?? "/app/access-control/history",
    meta: history48ccwjsLjPMeta || {},
    alias: history48ccwjsLjPMeta?.alias || [],
    redirect: history48ccwjsLjPMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/access-control/history.vue").then(m => m.default || m)
  },
  {
    name: indexeLs8BxWN4NMeta?.name ?? "app-access-control",
    path: indexeLs8BxWN4NMeta?.path ?? "/app/access-control",
    meta: indexeLs8BxWN4NMeta || {},
    alias: indexeLs8BxWN4NMeta?.alias || [],
    redirect: indexeLs8BxWN4NMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/access-control/index.vue").then(m => m.default || m)
  },
  {
    name: scanR8hCqLLOJSMeta?.name ?? "app-access-control-scan",
    path: scanR8hCqLLOJSMeta?.path ?? "/app/access-control/scan",
    meta: scanR8hCqLLOJSMeta || {},
    alias: scanR8hCqLLOJSMeta?.alias || [],
    redirect: scanR8hCqLLOJSMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/access-control/scan.vue").then(m => m.default || m)
  },
  {
    name: settingsSDt3rbaqJZMeta?.name ?? "app-access-control-settings",
    path: settingsSDt3rbaqJZMeta?.path ?? "/app/access-control/settings",
    meta: settingsSDt3rbaqJZMeta || {},
    alias: settingsSDt3rbaqJZMeta?.alias || [],
    redirect: settingsSDt3rbaqJZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/access-control/settings.vue").then(m => m.default || m)
  },
  {
    name: accreditation4wYCxUl8ckMeta?.name ?? "app-access-control-view-accreditation",
    path: accreditation4wYCxUl8ckMeta?.path ?? "/app/access-control/view/accreditation",
    meta: accreditation4wYCxUl8ckMeta || {},
    alias: accreditation4wYCxUl8ckMeta?.alias || [],
    redirect: accreditation4wYCxUl8ckMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/access-control/view/accreditation.vue").then(m => m.default || m)
  },
  {
    name: customertphSfGRpMUMeta?.name ?? "app-access-control-view-customer",
    path: customertphSfGRpMUMeta?.path ?? "/app/access-control/view/customer",
    meta: customertphSfGRpMUMeta || {},
    alias: customertphSfGRpMUMeta?.alias || [],
    redirect: customertphSfGRpMUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/access-control/view/customer.vue").then(m => m.default || m)
  },
  {
    name: orderGXhLm4snXLMeta?.name ?? "app-access-control-view-order",
    path: orderGXhLm4snXLMeta?.path ?? "/app/access-control/view/order",
    meta: orderGXhLm4snXLMeta || {},
    alias: orderGXhLm4snXLMeta?.alias || [],
    redirect: orderGXhLm4snXLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/access-control/view/order.vue").then(m => m.default || m)
  },
  {
    name: thirdparty_45ticketPg69eRgZgcMeta?.name ?? "app-access-control-view-thirdparty-ticket",
    path: thirdparty_45ticketPg69eRgZgcMeta?.path ?? "/app/access-control/view/thirdparty-ticket",
    meta: thirdparty_45ticketPg69eRgZgcMeta || {},
    alias: thirdparty_45ticketPg69eRgZgcMeta?.alias || [],
    redirect: thirdparty_45ticketPg69eRgZgcMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/access-control/view/thirdparty-ticket.vue").then(m => m.default || m)
  },
  {
    name: ticketPA8dxHEhF6Meta?.name ?? "app-access-control-view-ticket",
    path: ticketPA8dxHEhF6Meta?.path ?? "/app/access-control/view/ticket",
    meta: ticketPA8dxHEhF6Meta || {},
    alias: ticketPA8dxHEhF6Meta?.alias || [],
    redirect: ticketPA8dxHEhF6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/access-control/view/ticket.vue").then(m => m.default || m)
  },
  {
    name: indexKKkbSHq77aMeta?.name ?? "app-communication-audiences",
    path: indexKKkbSHq77aMeta?.path ?? "/app/communication/audiences",
    meta: indexKKkbSHq77aMeta || {},
    alias: indexKKkbSHq77aMeta?.alias || [],
    redirect: indexKKkbSHq77aMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/communication/audiences/index.vue").then(m => m.default || m)
  },
  {
    name: indexy3Tj3GJF87Meta?.name ?? "app-communication-build-email",
    path: indexy3Tj3GJF87Meta?.path ?? "/app/communication/build/email",
    meta: indexy3Tj3GJF87Meta || {},
    alias: indexy3Tj3GJF87Meta?.alias || [],
    redirect: indexy3Tj3GJF87Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/communication/build/email/index.vue").then(m => m.default || m)
  },
  {
    name: _91campaignId_93FUcsGHOOucMeta?.name ?? "app-communication-campaigns-campaignId",
    path: _91campaignId_93FUcsGHOOucMeta?.path ?? "/app/communication/campaigns/:campaignId()",
    meta: _91campaignId_93FUcsGHOOucMeta || {},
    alias: _91campaignId_93FUcsGHOOucMeta?.alias || [],
    redirect: _91campaignId_93FUcsGHOOucMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/communication/campaigns/[campaignId].vue").then(m => m.default || m)
  },
  {
    name: createlR7qBMgcGQMeta?.name ?? "app-communication-campaigns-create",
    path: createlR7qBMgcGQMeta?.path ?? "/app/communication/campaigns/create",
    meta: createlR7qBMgcGQMeta || {},
    alias: createlR7qBMgcGQMeta?.alias || [],
    redirect: createlR7qBMgcGQMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/communication/campaigns/create.vue").then(m => m.default || m)
  },
  {
    name: indexGpCaRki8enMeta?.name ?? "app-communication-campaigns",
    path: indexGpCaRki8enMeta?.path ?? "/app/communication/campaigns",
    meta: indexGpCaRki8enMeta || {},
    alias: indexGpCaRki8enMeta?.alias || [],
    redirect: indexGpCaRki8enMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/communication/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: indexfia3J9MHafMeta?.name ?? "app-communication",
    path: indexfia3J9MHafMeta?.path ?? "/app/communication",
    meta: indexfia3J9MHafMeta || {},
    alias: indexfia3J9MHafMeta?.alias || [],
    redirect: indexfia3J9MHafMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/communication/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93OCTdMjcRLSMeta?.name ?? "app-communication-lists-id",
    path: _91id_93OCTdMjcRLSMeta?.path ?? "/app/communication/lists/:id()",
    meta: _91id_93OCTdMjcRLSMeta || {},
    alias: _91id_93OCTdMjcRLSMeta?.alias || [],
    redirect: _91id_93OCTdMjcRLSMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/communication/lists/[id].vue").then(m => m.default || m)
  },
  {
    name: indexl9Db6ZZ1mKMeta?.name ?? "app-communication-lists",
    path: indexl9Db6ZZ1mKMeta?.path ?? "/app/communication/lists",
    meta: indexl9Db6ZZ1mKMeta || {},
    alias: indexl9Db6ZZ1mKMeta?.alias || [],
    redirect: indexl9Db6ZZ1mKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/communication/lists/index.vue").then(m => m.default || m)
  },
  {
    name: indexOvSdi2S4TAMeta?.name ?? "app-communication-media",
    path: indexOvSdi2S4TAMeta?.path ?? "/app/communication/media",
    meta: indexOvSdi2S4TAMeta || {},
    alias: indexOvSdi2S4TAMeta?.alias || [],
    redirect: indexOvSdi2S4TAMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/communication/media/index.vue").then(m => m.default || m)
  },
  {
    name: indexuq3WZVsMIXMeta?.name ?? "app-communication-templates",
    path: indexuq3WZVsMIXMeta?.path ?? "/app/communication/templates",
    meta: indexuq3WZVsMIXMeta || {},
    alias: indexuq3WZVsMIXMeta?.alias || [],
    redirect: indexuq3WZVsMIXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/communication/templates/index.vue").then(m => m.default || m)
  },
  {
    name: indexxhaEqjd5rzMeta?.name ?? "app",
    path: indexxhaEqjd5rzMeta?.path ?? "/app",
    meta: indexxhaEqjd5rzMeta || {},
    alias: indexxhaEqjd5rzMeta?.alias || [],
    redirect: indexxhaEqjd5rzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: indexdsNNEQ4IB7Meta?.name ?? "app-promote",
    path: indexdsNNEQ4IB7Meta?.path ?? "/app/promote",
    meta: indexdsNNEQ4IB7Meta || {},
    alias: indexdsNNEQ4IB7Meta?.alias || [],
    redirect: indexdsNNEQ4IB7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/promote/index.vue").then(m => m.default || m)
  },
  {
    name: indexN8A5fFkOd4Meta?.name ?? "app-reports",
    path: indexN8A5fFkOd4Meta?.path ?? "/app/reports",
    meta: indexN8A5fFkOd4Meta || {},
    alias: indexN8A5fFkOd4Meta?.alias || [],
    redirect: indexN8A5fFkOd4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/reports/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93N953rUH25DMeta?.name ?? "app-tenant-admin-coupons-id",
    path: _91id_93N953rUH25DMeta?.path ?? "/app/tenant-admin/coupons/:id()",
    meta: _91id_93N953rUH25DMeta || {},
    alias: _91id_93N953rUH25DMeta?.alias || [],
    redirect: _91id_93N953rUH25DMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/coupons/[id].vue").then(m => m.default || m)
  },
  {
    name: indexxxQQY9xER5Meta?.name ?? "app-tenant-admin-coupons",
    path: indexxxQQY9xER5Meta?.path ?? "/app/tenant-admin/coupons",
    meta: indexxxQQY9xER5Meta || {},
    alias: indexxxQQY9xER5Meta?.alias || [],
    redirect: indexxxQQY9xER5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/coupons/index.vue").then(m => m.default || m)
  },
  {
    name: create_45new_45event1ScBTnNrAfMeta?.name ?? "app-tenant-admin-create-new-event",
    path: create_45new_45event1ScBTnNrAfMeta?.path ?? "/app/tenant-admin/create-new-event",
    meta: create_45new_45event1ScBTnNrAfMeta || {},
    alias: create_45new_45event1ScBTnNrAfMeta?.alias || [],
    redirect: create_45new_45event1ScBTnNrAfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/create-new-event.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ONopOPqhuwMeta?.name ?? "app-tenant-admin-customers-id",
    path: _91id_93ONopOPqhuwMeta?.path ?? "/app/tenant-admin/customers/:id()",
    meta: _91id_93ONopOPqhuwMeta || {},
    alias: _91id_93ONopOPqhuwMeta?.alias || [],
    redirect: _91id_93ONopOPqhuwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/customers/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPxLB7CUqq8Meta?.name ?? "app-tenant-admin-customers",
    path: indexPxLB7CUqq8Meta?.path ?? "/app/tenant-admin/customers",
    meta: indexPxLB7CUqq8Meta || {},
    alias: indexPxLB7CUqq8Meta?.alias || [],
    redirect: indexPxLB7CUqq8Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/customers/index.vue").then(m => m.default || m)
  },
  {
    path: _91id_93qzz0FzVIF7Meta?.path ?? "/app/tenant-admin/events/:id()",
    children: [
  {
    name: analyticsmDq2oEVOIyMeta?.name ?? "app-tenant-admin-events-id-analytics",
    path: analyticsmDq2oEVOIyMeta?.path ?? "analytics",
    meta: analyticsmDq2oEVOIyMeta || {},
    alias: analyticsmDq2oEVOIyMeta?.alias || [],
    redirect: analyticsmDq2oEVOIyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/analytics.vue").then(m => m.default || m)
  },
  {
    name: _91broadcastId_93s6948yRjjVMeta?.name ?? "app-tenant-admin-events-id-broadcasts-broadcastId",
    path: _91broadcastId_93s6948yRjjVMeta?.path ?? "broadcasts/:broadcastId()",
    meta: _91broadcastId_93s6948yRjjVMeta || {},
    alias: _91broadcastId_93s6948yRjjVMeta?.alias || [],
    redirect: _91broadcastId_93s6948yRjjVMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/broadcasts/[broadcastId].vue").then(m => m.default || m)
  },
  {
    name: indexc7IAMLciDsMeta?.name ?? "app-tenant-admin-events-id-broadcasts",
    path: indexc7IAMLciDsMeta?.path ?? "broadcasts",
    meta: indexc7IAMLciDsMeta || {},
    alias: indexc7IAMLciDsMeta?.alias || [],
    redirect: indexc7IAMLciDsMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/broadcasts/index.vue").then(m => m.default || m)
  },
  {
    name: detailsVCdBLpqkEuMeta?.name ?? "app-tenant-admin-events-id-details",
    path: detailsVCdBLpqkEuMeta?.path ?? "details",
    meta: detailsVCdBLpqkEuMeta || {},
    alias: detailsVCdBLpqkEuMeta?.alias || [],
    redirect: detailsVCdBLpqkEuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/details.vue").then(m => m.default || m)
  },
  {
    name: doorlistN0CPYeSDARMeta?.name ?? "app-tenant-admin-events-id-doorlist",
    path: doorlistN0CPYeSDARMeta?.path ?? "doorlist",
    meta: doorlistN0CPYeSDARMeta || {},
    alias: doorlistN0CPYeSDARMeta?.alias || [],
    redirect: doorlistN0CPYeSDARMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/doorlist.vue").then(m => m.default || m)
  },
  {
    name: duplicateJ6gNrVAvhEMeta?.name ?? "app-tenant-admin-events-id-duplicate",
    path: duplicateJ6gNrVAvhEMeta?.path ?? "duplicate",
    meta: duplicateJ6gNrVAvhEMeta || {},
    alias: duplicateJ6gNrVAvhEMeta?.alias || [],
    redirect: duplicateJ6gNrVAvhEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/duplicate.vue").then(m => m.default || m)
  },
  {
    name: guest_45listpuHplvkuKuMeta?.name ?? "app-tenant-admin-events-id-guest-list",
    path: guest_45listpuHplvkuKuMeta?.path ?? "guest-list",
    meta: guest_45listpuHplvkuKuMeta || {},
    alias: guest_45listpuHplvkuKuMeta?.alias || [],
    redirect: guest_45listpuHplvkuKuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/guest-list.vue").then(m => m.default || m)
  },
  {
    name: import_45scanned_45ticketsdMXULpOFFwMeta?.name ?? "app-tenant-admin-events-id-import-scanned-tickets",
    path: import_45scanned_45ticketsdMXULpOFFwMeta?.path ?? "import-scanned-tickets",
    meta: import_45scanned_45ticketsdMXULpOFFwMeta || {},
    alias: import_45scanned_45ticketsdMXULpOFFwMeta?.alias || [],
    redirect: import_45scanned_45ticketsdMXULpOFFwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/import-scanned-tickets.vue").then(m => m.default || m)
  },
  {
    name: import_45tickets0HRV0SLzvWMeta?.name ?? "app-tenant-admin-events-id-import-tickets",
    path: import_45tickets0HRV0SLzvWMeta?.path ?? "import-tickets",
    meta: import_45tickets0HRV0SLzvWMeta || {},
    alias: import_45tickets0HRV0SLzvWMeta?.alias || [],
    redirect: import_45tickets0HRV0SLzvWMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/import-tickets.vue").then(m => m.default || m)
  },
  {
    name: imported_45tickets7VWL6WJUcpMeta?.name ?? "app-tenant-admin-events-id-imported-tickets",
    path: imported_45tickets7VWL6WJUcpMeta?.path ?? "imported-tickets",
    meta: imported_45tickets7VWL6WJUcpMeta || {},
    alias: imported_45tickets7VWL6WJUcpMeta?.alias || [],
    redirect: imported_45tickets7VWL6WJUcpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/imported-tickets.vue").then(m => m.default || m)
  },
  {
    name: indexztEpJGk8BgMeta?.name ?? "app-tenant-admin-events-id",
    path: indexztEpJGk8BgMeta?.path ?? "",
    meta: indexztEpJGk8BgMeta || {},
    alias: indexztEpJGk8BgMeta?.alias || [],
    redirect: indexztEpJGk8BgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: integrationsol3CvG5TbiMeta?.name ?? "app-tenant-admin-events-id-integrations",
    path: integrationsol3CvG5TbiMeta?.path ?? "integrations",
    meta: integrationsol3CvG5TbiMeta || {},
    alias: integrationsol3CvG5TbiMeta?.alias || [],
    redirect: integrationsol3CvG5TbiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/integrations.vue").then(m => m.default || m)
  },
  {
    name: issued_45ticketsgv9feVciSPMeta?.name ?? "app-tenant-admin-events-id-issued-tickets",
    path: issued_45ticketsgv9feVciSPMeta?.path ?? "issued-tickets",
    meta: issued_45ticketsgv9feVciSPMeta || {},
    alias: issued_45ticketsgv9feVciSPMeta?.alias || [],
    redirect: issued_45ticketsgv9feVciSPMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/issued-tickets.vue").then(m => m.default || m)
  },
  {
    name: liveHc3YiqYUUKMeta?.name ?? "app-tenant-admin-events-id-live",
    path: liveHc3YiqYUUKMeta?.path ?? "live",
    meta: liveHc3YiqYUUKMeta || {},
    alias: liveHc3YiqYUUKMeta?.alias || [],
    redirect: liveHc3YiqYUUKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/live.vue").then(m => m.default || m)
  },
  {
    name: sales_45reporty3aY7RnGZEMeta?.name ?? "app-tenant-admin-events-id-sales-report",
    path: sales_45reporty3aY7RnGZEMeta?.path ?? "sales-report",
    meta: sales_45reporty3aY7RnGZEMeta || {},
    alias: sales_45reporty3aY7RnGZEMeta?.alias || [],
    redirect: sales_45reporty3aY7RnGZEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/sales-report.vue").then(m => m.default || m)
  },
  {
    name: settingsPt9nD0OSzDMeta?.name ?? "app-tenant-admin-events-id-settings",
    path: settingsPt9nD0OSzDMeta?.path ?? "settings",
    meta: settingsPt9nD0OSzDMeta || {},
    alias: settingsPt9nD0OSzDMeta?.alias || [],
    redirect: settingsPt9nD0OSzDMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/settings.vue").then(m => m.default || m)
  },
  {
    name: shop_45settingsTvtxD4XV2bMeta?.name ?? "app-tenant-admin-events-id-shop-settings",
    path: shop_45settingsTvtxD4XV2bMeta?.path ?? "shop-settings",
    meta: shop_45settingsTvtxD4XV2bMeta || {},
    alias: shop_45settingsTvtxD4XV2bMeta?.alias || [],
    redirect: shop_45settingsTvtxD4XV2bMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/shop-settings.vue").then(m => m.default || m)
  },
  {
    name: ticket_45typestBB5xE8slnMeta?.name ?? "app-tenant-admin-events-id-ticket-types",
    path: ticket_45typestBB5xE8slnMeta?.path ?? "ticket-types",
    meta: ticket_45typestBB5xE8slnMeta || {},
    alias: ticket_45typestBB5xE8slnMeta?.alias || [],
    redirect: ticket_45typestBB5xE8slnMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/ticket-types.vue").then(m => m.default || m)
  },
  {
    name: toolsLZf9aiuv0nMeta?.name ?? "app-tenant-admin-events-id-tools",
    path: toolsLZf9aiuv0nMeta?.path ?? "tools",
    meta: toolsLZf9aiuv0nMeta || {},
    alias: toolsLZf9aiuv0nMeta?.alias || [],
    redirect: toolsLZf9aiuv0nMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/tools.vue").then(m => m.default || m)
  },
  {
    name: waitlist_45signupsliQnkTJZ1dMeta?.name ?? "app-tenant-admin-events-id-waitlist-signups",
    path: waitlist_45signupsliQnkTJZ1dMeta?.path ?? "waitlist-signups",
    meta: waitlist_45signupsliQnkTJZ1dMeta || {},
    alias: waitlist_45signupsliQnkTJZ1dMeta?.alias || [],
    redirect: waitlist_45signupsliQnkTJZ1dMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id]/waitlist-signups.vue").then(m => m.default || m)
  }
],
    name: _91id_93qzz0FzVIF7Meta?.name ?? undefined,
    meta: _91id_93qzz0FzVIF7Meta || {},
    alias: _91id_93qzz0FzVIF7Meta?.alias || [],
    redirect: _91id_93qzz0FzVIF7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/events/[id].vue").then(m => m.default || m)
  },
  {
    name: indexjLnA9Rscm4Meta?.name ?? "app-tenant-admin-events",
    path: indexjLnA9Rscm4Meta?.path ?? "/app/tenant-admin/events",
    meta: indexjLnA9Rscm4Meta || {},
    alias: indexjLnA9Rscm4Meta?.alias || [],
    redirect: indexjLnA9Rscm4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/events/index.vue").then(m => m.default || m)
  },
  {
    name: helpEiW5VKKH0eMeta?.name ?? "app-tenant-admin-help",
    path: helpEiW5VKKH0eMeta?.path ?? "/app/tenant-admin/help",
    meta: helpEiW5VKKH0eMeta || {},
    alias: helpEiW5VKKH0eMeta?.alias || [],
    redirect: helpEiW5VKKH0eMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/help.vue").then(m => m.default || m)
  },
  {
    name: indexLBlEcNKfxNMeta?.name ?? "app-tenant-admin",
    path: indexLBlEcNKfxNMeta?.path ?? "/app/tenant-admin",
    meta: indexLBlEcNKfxNMeta || {},
    alias: indexLBlEcNKfxNMeta?.alias || [],
    redirect: indexLBlEcNKfxNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/index.vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93fdAGNb6kN9Meta?.name ?? "app-tenant-admin-integrations-integrationId-eventId",
    path: _91eventId_93fdAGNb6kN9Meta?.path ?? "/app/tenant-admin/integrations/:integrationId()/:eventId()",
    meta: _91eventId_93fdAGNb6kN9Meta || {},
    alias: _91eventId_93fdAGNb6kN9Meta?.alias || [],
    redirect: _91eventId_93fdAGNb6kN9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/integrations/[integrationId]/[eventId].vue").then(m => m.default || m)
  },
  {
    name: indexWSlLyQZFhiMeta?.name ?? "app-tenant-admin-integrations-integrationId",
    path: indexWSlLyQZFhiMeta?.path ?? "/app/tenant-admin/integrations/:integrationId()",
    meta: indexWSlLyQZFhiMeta || {},
    alias: indexWSlLyQZFhiMeta?.alias || [],
    redirect: indexWSlLyQZFhiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/integrations/[integrationId]/index.vue").then(m => m.default || m)
  },
  {
    name: create6QBsMlqstnMeta?.name ?? "app-tenant-admin-integrations-create",
    path: create6QBsMlqstnMeta?.path ?? "/app/tenant-admin/integrations/create",
    meta: create6QBsMlqstnMeta || {},
    alias: create6QBsMlqstnMeta?.alias || [],
    redirect: create6QBsMlqstnMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/integrations/create.vue").then(m => m.default || m)
  },
  {
    name: indexhT5jvYgvK2Meta?.name ?? "app-tenant-admin-integrations",
    path: indexhT5jvYgvK2Meta?.path ?? "/app/tenant-admin/integrations",
    meta: indexhT5jvYgvK2Meta || {},
    alias: indexhT5jvYgvK2Meta?.alias || [],
    redirect: indexhT5jvYgvK2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93uioUTUwfdoMeta?.name ?? "app-tenant-admin-invoices-id",
    path: _91id_93uioUTUwfdoMeta?.path ?? "/app/tenant-admin/invoices/:id()",
    meta: _91id_93uioUTUwfdoMeta || {},
    alias: _91id_93uioUTUwfdoMeta?.alias || [],
    redirect: _91id_93uioUTUwfdoMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/invoices/[id].vue").then(m => m.default || m)
  },
  {
    name: indexerBEk1BlTwMeta?.name ?? "app-tenant-admin-invoices",
    path: indexerBEk1BlTwMeta?.path ?? "/app/tenant-admin/invoices",
    meta: indexerBEk1BlTwMeta || {},
    alias: indexerBEk1BlTwMeta?.alias || [],
    redirect: indexerBEk1BlTwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: logoutRdaI3cxg9uMeta?.name ?? "app-tenant-admin-logout",
    path: logoutRdaI3cxg9uMeta?.path ?? "/app/tenant-admin/logout",
    meta: logoutRdaI3cxg9uMeta || {},
    alias: logoutRdaI3cxg9uMeta?.alias || [],
    redirect: logoutRdaI3cxg9uMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/logout.vue").then(m => m.default || m)
  },
  {
    name: messagesuOurZj0lbTMeta?.name ?? "app-tenant-admin-messages",
    path: messagesuOurZj0lbTMeta?.path ?? "/app/tenant-admin/messages",
    meta: messagesuOurZj0lbTMeta || {},
    alias: messagesuOurZj0lbTMeta?.alias || [],
    redirect: messagesuOurZj0lbTMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/messages.vue").then(m => m.default || m)
  },
  {
    name: morep902k2C98mMeta?.name ?? "app-tenant-admin-more",
    path: morep902k2C98mMeta?.path ?? "/app/tenant-admin/more",
    children: [
  {
    name: _91id_93ICa2NKrBaGMeta?.name ?? "app-tenant-admin-more-campaigns-id",
    path: _91id_93ICa2NKrBaGMeta?.path ?? "campaigns/:id()",
    meta: _91id_93ICa2NKrBaGMeta || {},
    alias: _91id_93ICa2NKrBaGMeta?.alias || [],
    redirect: _91id_93ICa2NKrBaGMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/more/campaigns/[id].vue").then(m => m.default || m)
  },
  {
    name: communication5qDIcw02eHMeta?.name ?? "app-tenant-admin-more-communication",
    path: communication5qDIcw02eHMeta?.path ?? "communication",
    meta: communication5qDIcw02eHMeta || {},
    alias: communication5qDIcw02eHMeta?.alias || [],
    redirect: communication5qDIcw02eHMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/more/communication.vue").then(m => m.default || m)
  },
  {
    name: indexP2BC9ftzSqMeta?.name ?? "app-tenant-admin-more-emails",
    path: indexP2BC9ftzSqMeta?.path ?? "emails",
    meta: indexP2BC9ftzSqMeta || {},
    alias: indexP2BC9ftzSqMeta?.alias || [],
    redirect: indexP2BC9ftzSqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/more/emails/index.vue").then(m => m.default || m)
  },
  {
    name: indexLRBt0wdyTpMeta?.name ?? "app-tenant-admin-more-image-library",
    path: indexLRBt0wdyTpMeta?.path ?? "image-library",
    meta: indexLRBt0wdyTpMeta || {},
    alias: indexLRBt0wdyTpMeta?.alias || [],
    redirect: indexLRBt0wdyTpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/more/image-library/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93aRplLMW59PMeta?.name ?? "app-tenant-admin-more-mailing-lists-id",
    path: _91id_93aRplLMW59PMeta?.path ?? "mailing-lists/:id()",
    meta: _91id_93aRplLMW59PMeta || {},
    alias: _91id_93aRplLMW59PMeta?.alias || [],
    redirect: _91id_93aRplLMW59PMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/more/mailing-lists/[id].vue").then(m => m.default || m)
  }
],
    meta: morep902k2C98mMeta || {},
    alias: morep902k2C98mMeta?.alias || [],
    redirect: morep902k2C98mMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/more.vue").then(m => m.default || m)
  },
  {
    name: indexysQd51AiHyMeta?.name ?? "app-tenant-admin-order-edit-orderid",
    path: indexysQd51AiHyMeta?.path ?? "/app/tenant-admin/order-edit/:orderid()",
    meta: indexysQd51AiHyMeta || {},
    alias: indexysQd51AiHyMeta?.alias || [],
    redirect: indexysQd51AiHyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/order-edit/[orderid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexu3CwzE0AO9Meta?.name ?? "app-tenant-admin-order-edit",
    path: indexu3CwzE0AO9Meta?.path ?? "/app/tenant-admin/order-edit",
    meta: indexu3CwzE0AO9Meta || {},
    alias: indexu3CwzE0AO9Meta?.alias || [],
    redirect: indexu3CwzE0AO9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/order-edit/index.vue").then(m => m.default || m)
  },
  {
    name: _91orderid_93etjKc72qW3Meta?.name ?? "app-tenant-admin-orders-orderid",
    path: _91orderid_93etjKc72qW3Meta?.path ?? "/app/tenant-admin/orders/:orderid()",
    meta: _91orderid_93etjKc72qW3Meta || {},
    alias: _91orderid_93etjKc72qW3Meta?.alias || [],
    redirect: _91orderid_93etjKc72qW3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/orders/[orderid].vue").then(m => m.default || m)
  },
  {
    name: indexdVMk3lhD9uMeta?.name ?? "app-tenant-admin-orders-create",
    path: indexdVMk3lhD9uMeta?.path ?? "/app/tenant-admin/orders/create",
    meta: indexdVMk3lhD9uMeta || {},
    alias: indexdVMk3lhD9uMeta?.alias || [],
    redirect: indexdVMk3lhD9uMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/orders/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexntUWATeXGwMeta?.name ?? "app-tenant-admin-orders",
    path: indexntUWATeXGwMeta?.path ?? "/app/tenant-admin/orders",
    meta: indexntUWATeXGwMeta || {},
    alias: indexntUWATeXGwMeta?.alias || [],
    redirect: indexntUWATeXGwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexOCYgL95iFIMeta?.name ?? "app-tenant-admin-orders-search",
    path: indexOCYgL95iFIMeta?.path ?? "/app/tenant-admin/orders/search",
    meta: indexOCYgL95iFIMeta || {},
    alias: indexOCYgL95iFIMeta?.alias || [],
    redirect: indexOCYgL95iFIMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/orders/search/index.vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93xkfyB84aJ1Meta?.name ?? "app-tenant-admin-orders-third-party-order-orderId",
    path: _91orderId_93xkfyB84aJ1Meta?.path ?? "/app/tenant-admin/orders/third-party-order/:orderId()",
    meta: _91orderId_93xkfyB84aJ1Meta || {},
    alias: _91orderId_93xkfyB84aJ1Meta?.alias || [],
    redirect: _91orderId_93xkfyB84aJ1Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/orders/third-party-order/[orderId].vue").then(m => m.default || m)
  },
  {
    name: index84SKmldNR5Meta?.name ?? "app-tenant-admin-scanners",
    path: index84SKmldNR5Meta?.path ?? "/app/tenant-admin/scanners",
    meta: index84SKmldNR5Meta || {},
    alias: index84SKmldNR5Meta?.alias || [],
    redirect: index84SKmldNR5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/scanners/index.vue").then(m => m.default || m)
  },
  {
    name: settingsqngMOT43eLMeta?.name ?? "app-tenant-admin-settings",
    path: settingsqngMOT43eLMeta?.path ?? "/app/tenant-admin/settings",
    meta: settingsqngMOT43eLMeta || {},
    alias: settingsqngMOT43eLMeta?.alias || [],
    redirect: settingsqngMOT43eLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/settings.vue").then(m => m.default || m)
  },
  {
    name: reauthWHGhTGGzggMeta?.name ?? "app-tenant-admin-stripe-connect-reauth",
    path: reauthWHGhTGGzggMeta?.path ?? "/app/tenant-admin/stripe-connect/reauth",
    meta: reauthWHGhTGGzggMeta || {},
    alias: reauthWHGhTGGzggMeta?.alias || [],
    redirect: reauthWHGhTGGzggMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/stripe-connect/reauth.vue").then(m => m.default || m)
  },
  {
    name: returnKF7j8QdZoxMeta?.name ?? "app-tenant-admin-stripe-connect-return",
    path: returnKF7j8QdZoxMeta?.path ?? "/app/tenant-admin/stripe-connect/return",
    meta: returnKF7j8QdZoxMeta || {},
    alias: returnKF7j8QdZoxMeta?.alias || [],
    redirect: returnKF7j8QdZoxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/stripe-connect/return.vue").then(m => m.default || m)
  },
  {
    name: indexQvX3mgyWdEMeta?.name ?? "app-tenant-admin-tickets",
    path: indexQvX3mgyWdEMeta?.path ?? "/app/tenant-admin/tickets",
    meta: indexQvX3mgyWdEMeta || {},
    alias: indexQvX3mgyWdEMeta?.alias || [],
    redirect: indexQvX3mgyWdEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: transactionsYwQioAOuRxMeta?.name ?? "app-tenant-admin-transactions",
    path: transactionsYwQioAOuRxMeta?.path ?? "/app/tenant-admin/transactions",
    meta: transactionsYwQioAOuRxMeta || {},
    alias: transactionsYwQioAOuRxMeta?.alias || [],
    redirect: transactionsYwQioAOuRxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/tenant-admin/transactions.vue").then(m => m.default || m)
  },
  {
    name: helpJpC16QVQwLMeta?.name ?? "app-user-help",
    path: helpJpC16QVQwLMeta?.path ?? "/app/user/help",
    meta: helpJpC16QVQwLMeta || {},
    alias: helpJpC16QVQwLMeta?.alias || [],
    redirect: helpJpC16QVQwLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/user/help.vue").then(m => m.default || m)
  },
  {
    name: indexBZMaIpqAdwMeta?.name ?? "app-user",
    path: indexBZMaIpqAdwMeta?.path ?? "/app/user",
    meta: indexBZMaIpqAdwMeta || {},
    alias: indexBZMaIpqAdwMeta?.alias || [],
    redirect: indexBZMaIpqAdwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/user/index.vue").then(m => m.default || m)
  },
  {
    name: orderss56eEpYEUJMeta?.name ?? "app-user-orders",
    path: orderss56eEpYEUJMeta?.path ?? "/app/user/orders",
    meta: orderss56eEpYEUJMeta || {},
    alias: orderss56eEpYEUJMeta?.alias || [],
    redirect: orderss56eEpYEUJMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/user/orders.vue").then(m => m.default || m)
  },
  {
    name: payment_45methodskJPe1DhhMbMeta?.name ?? "app-user-payment-methods",
    path: payment_45methodskJPe1DhhMbMeta?.path ?? "/app/user/payment-methods",
    meta: payment_45methodskJPe1DhhMbMeta || {},
    alias: payment_45methodskJPe1DhhMbMeta?.alias || [],
    redirect: payment_45methodskJPe1DhhMbMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/user/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: plansyyAcNiPG1IMeta?.name ?? "app-user-plans",
    path: plansyyAcNiPG1IMeta?.path ?? "/app/user/plans",
    meta: plansyyAcNiPG1IMeta || {},
    alias: plansyyAcNiPG1IMeta?.alias || [],
    redirect: plansyyAcNiPG1IMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/user/plans.vue").then(m => m.default || m)
  },
  {
    name: profilejPgxkH4QzKMeta?.name ?? "app-user-profile",
    path: profilejPgxkH4QzKMeta?.path ?? "/app/user/profile",
    children: [
  {
    name: billingEeRNKTaegEMeta?.name ?? "app-user-profile-billing",
    path: billingEeRNKTaegEMeta?.path ?? "billing",
    meta: billingEeRNKTaegEMeta || {},
    alias: billingEeRNKTaegEMeta?.alias || [],
    redirect: billingEeRNKTaegEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/user/profile/billing.vue").then(m => m.default || m)
  },
  {
    name: general3MEgyDAOGjMeta?.name ?? "app-user-profile-general",
    path: general3MEgyDAOGjMeta?.path ?? "general",
    meta: general3MEgyDAOGjMeta || {},
    alias: general3MEgyDAOGjMeta?.alias || [],
    redirect: general3MEgyDAOGjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/user/profile/general.vue").then(m => m.default || m)
  },
  {
    name: passwordXVI39BXEIjMeta?.name ?? "app-user-profile-password",
    path: passwordXVI39BXEIjMeta?.path ?? "password",
    meta: passwordXVI39BXEIjMeta || {},
    alias: passwordXVI39BXEIjMeta?.alias || [],
    redirect: passwordXVI39BXEIjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/user/profile/password.vue").then(m => m.default || m)
  }
],
    meta: profilejPgxkH4QzKMeta || {},
    alias: profilejPgxkH4QzKMeta?.alias || [],
    redirect: profilejPgxkH4QzKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/user/profile.vue").then(m => m.default || m)
  },
  {
    name: share3U7msZOK95Meta?.name ?? "app-user-share",
    path: share3U7msZOK95Meta?.path ?? "/app/user/share",
    meta: share3U7msZOK95Meta || {},
    alias: share3U7msZOK95Meta?.alias || [],
    redirect: share3U7msZOK95Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/user/share.vue").then(m => m.default || m)
  },
  {
    name: sign_45outXdlT7Ee0tkMeta?.name ?? "app-user-sign-out",
    path: sign_45outXdlT7Ee0tkMeta?.path ?? "/app/user/sign-out",
    meta: sign_45outXdlT7Ee0tkMeta || {},
    alias: sign_45outXdlT7Ee0tkMeta?.alias || [],
    redirect: sign_45outXdlT7Ee0tkMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/app/user/sign-out.vue").then(m => m.default || m)
  },
  {
    name: active_45tenantpcoKggrcXvMeta?.name ?? "dev-active-tenant",
    path: active_45tenantpcoKggrcXvMeta?.path ?? "/dev/active-tenant",
    meta: active_45tenantpcoKggrcXvMeta || {},
    alias: active_45tenantpcoKggrcXvMeta?.alias || [],
    redirect: active_45tenantpcoKggrcXvMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/dev/active-tenant.vue").then(m => m.default || m)
  },
  {
    name: alt_45account_45home4XDwgMyfkNMeta?.name ?? "dev-alt-account-home",
    path: alt_45account_45home4XDwgMyfkNMeta?.path ?? "/dev/alt-account-home",
    meta: alt_45account_45home4XDwgMyfkNMeta || {},
    alias: alt_45account_45home4XDwgMyfkNMeta?.alias || [],
    redirect: alt_45account_45home4XDwgMyfkNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/dev/alt-account-home.vue").then(m => m.default || m)
  },
  {
    name: confign4xdA2GreyMeta?.name ?? "dev-config",
    path: confign4xdA2GreyMeta?.path ?? "/dev/config",
    meta: confign4xdA2GreyMeta || {},
    alias: confign4xdA2GreyMeta?.alias || [],
    redirect: confign4xdA2GreyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/dev/config.vue").then(m => m.default || m)
  },
  {
    name: errortestCmjHXk5Xx8Meta?.name ?? "dev-errortest",
    path: errortestCmjHXk5Xx8Meta?.path ?? "/dev/errortest",
    meta: errortestCmjHXk5Xx8Meta || {},
    alias: errortestCmjHXk5Xx8Meta?.alias || [],
    redirect: errortestCmjHXk5Xx8Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/dev/errortest.vue").then(m => m.default || m)
  },
  {
    name: indexwJRNHwFfL9Meta?.name ?? "dev",
    path: indexwJRNHwFfL9Meta?.path ?? "/dev",
    meta: indexwJRNHwFfL9Meta || {},
    alias: indexwJRNHwFfL9Meta?.alias || [],
    redirect: indexwJRNHwFfL9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/dev/index.vue").then(m => m.default || m)
  },
  {
    name: layout_452IBTzBXAHMaMeta?.name ?? "dev-layout-2",
    path: layout_452IBTzBXAHMaMeta?.path ?? "/dev/layout-2",
    meta: layout_452IBTzBXAHMaMeta || {},
    alias: layout_452IBTzBXAHMaMeta?.alias || [],
    redirect: layout_452IBTzBXAHMaMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/dev/layout-2.vue").then(m => m.default || m)
  },
  {
    name: sidebarfe6htQ7LS3Meta?.name ?? "dev-sidebar",
    path: sidebarfe6htQ7LS3Meta?.path ?? "/dev/sidebar",
    meta: sidebarfe6htQ7LS3Meta || {},
    alias: sidebarfe6htQ7LS3Meta?.alias || [],
    redirect: sidebarfe6htQ7LS3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/dev/sidebar.vue").then(m => m.default || m)
  },
  {
    name: ssrko3cFwPiAZMeta?.name ?? "dev-ssr",
    path: ssrko3cFwPiAZMeta?.path ?? "/dev/ssr",
    meta: ssrko3cFwPiAZMeta || {},
    alias: ssrko3cFwPiAZMeta?.alias || [],
    redirect: ssrko3cFwPiAZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/dev/ssr.vue").then(m => m.default || m)
  },
  {
    name: indexLkXUKCmPncMeta?.name ?? "download-tickets",
    path: indexLkXUKCmPncMeta?.path ?? "/download-tickets",
    meta: indexLkXUKCmPncMeta || {},
    alias: indexLkXUKCmPncMeta?.alias || [],
    redirect: indexLkXUKCmPncMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/download-tickets/index.vue").then(m => m.default || m)
  },
  {
    name: homeOB00DrN7vTMeta?.name ?? "home",
    path: homeOB00DrN7vTMeta?.path ?? "/home",
    meta: homeOB00DrN7vTMeta || {},
    alias: homeOB00DrN7vTMeta?.alias || [],
    redirect: homeOB00DrN7vTMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexqed5IaIOjBMeta?.name ?? "index",
    path: indexqed5IaIOjBMeta?.path ?? "/",
    meta: indexqed5IaIOjBMeta || {},
    alias: indexqed5IaIOjBMeta?.alias || [],
    redirect: indexqed5IaIOjBMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/index.vue").then(m => m.default || m)
  },
  {
    name: landingWqMjQDtlQjMeta?.name ?? "landing",
    path: landingWqMjQDtlQjMeta?.path ?? "/landing",
    meta: landingWqMjQDtlQjMeta || {},
    alias: landingWqMjQDtlQjMeta?.alias || [],
    redirect: landingWqMjQDtlQjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/landing.vue").then(m => m.default || m)
  },
  {
    name: codeoKYoam8tnPMeta?.name ?? "login-code",
    path: codeoKYoam8tnPMeta?.path ?? "/login/code",
    meta: codeoKYoam8tnPMeta || {},
    alias: codeoKYoam8tnPMeta?.alias || [],
    redirect: codeoKYoam8tnPMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/login/code.vue").then(m => m.default || m)
  },
  {
    name: email_45passwordUwrz4ieBqEMeta?.name ?? "login-email-password",
    path: email_45passwordUwrz4ieBqEMeta?.path ?? "/login/email-password",
    meta: email_45passwordUwrz4ieBqEMeta || {},
    alias: email_45passwordUwrz4ieBqEMeta?.alias || [],
    redirect: email_45passwordUwrz4ieBqEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/login/email-password.vue").then(m => m.default || m)
  },
  {
    name: index8RaOjk7WdkMeta?.name ?? "login",
    path: index8RaOjk7WdkMeta?.path ?? "/login",
    meta: index8RaOjk7WdkMeta || {},
    alias: index8RaOjk7WdkMeta?.alias || [],
    redirect: index8RaOjk7WdkMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: welcomeZiDFD3bVhLMeta?.name ?? "login-welcome",
    path: welcomeZiDFD3bVhLMeta?.path ?? "/login/welcome",
    meta: welcomeZiDFD3bVhLMeta || {},
    alias: welcomeZiDFD3bVhLMeta?.alias || [],
    redirect: welcomeZiDFD3bVhLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/login/welcome.vue").then(m => m.default || m)
  },
  {
    name: complete_45onboarding9BAaIqbaqjMeta?.name ?? "onboarding-complete-onboarding",
    path: complete_45onboarding9BAaIqbaqjMeta?.path ?? "/onboarding/complete-onboarding",
    meta: complete_45onboarding9BAaIqbaqjMeta || {},
    alias: complete_45onboarding9BAaIqbaqjMeta?.alias || [],
    redirect: complete_45onboarding9BAaIqbaqjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/onboarding/complete-onboarding.vue").then(m => m.default || m)
  },
  {
    name: indexv2BIrxWVxWMeta?.name ?? "onboarding",
    path: indexv2BIrxWVxWMeta?.path ?? "/onboarding",
    meta: indexv2BIrxWVxWMeta || {},
    alias: indexv2BIrxWVxWMeta?.alias || [],
    redirect: indexv2BIrxWVxWMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: pricingRv7XcT3PydMeta?.name ?? "pricing",
    path: pricingRv7XcT3PydMeta?.path ?? "/pricing",
    meta: pricingRv7XcT3PydMeta || {},
    alias: pricingRv7XcT3PydMeta?.alias || [],
    redirect: pricingRv7XcT3PydMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: quickv5mEj5VdU8Meta?.name ?? "quick",
    path: quickv5mEj5VdU8Meta?.path ?? "/quick",
    meta: quickv5mEj5VdU8Meta || {},
    alias: quickv5mEj5VdU8Meta?.alias || [],
    redirect: quickv5mEj5VdU8Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/quick.vue").then(m => m.default || m)
  },
  {
    name: registerzqPmLbGQcfMeta?.name ?? "register",
    path: registerzqPmLbGQcfMeta?.path ?? "/register",
    meta: registerzqPmLbGQcfMeta || {},
    alias: registerzqPmLbGQcfMeta?.alias || [],
    redirect: registerzqPmLbGQcfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/register.vue").then(m => m.default || m)
  },
  {
    name: indexxgmBQCblvoMeta?.name ?? "scan-code",
    path: indexxgmBQCblvoMeta?.path ?? "/scan/code",
    meta: indexxgmBQCblvoMeta || {},
    alias: indexxgmBQCblvoMeta?.alias || [],
    redirect: indexxgmBQCblvoMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/scan/code/index.vue").then(m => m.default || m)
  },
  {
    name: lookup5T5zEVFa88Meta?.name ?? "scan-code-lookup",
    path: lookup5T5zEVFa88Meta?.path ?? "/scan/code/lookup",
    meta: lookup5T5zEVFa88Meta || {},
    alias: lookup5T5zEVFa88Meta?.alias || [],
    redirect: lookup5T5zEVFa88Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/scan/code/lookup.vue").then(m => m.default || m)
  },
  {
    name: index_45oldXgTjNeBWfMMeta?.name ?? "scan-index-old",
    path: index_45oldXgTjNeBWfMMeta?.path ?? "/scan/index-old",
    meta: index_45oldXgTjNeBWfMMeta || {},
    alias: index_45oldXgTjNeBWfMMeta?.alias || [],
    redirect: index_45oldXgTjNeBWfMMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/scan/index-old.vue").then(m => m.default || m)
  },
  {
    name: indexRycQBdVLNrMeta?.name ?? "scan",
    path: indexRycQBdVLNrMeta?.path ?? "/scan",
    meta: indexRycQBdVLNrMeta || {},
    alias: indexRycQBdVLNrMeta?.alias || [],
    redirect: indexRycQBdVLNrMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/scan/index.vue").then(m => m.default || m)
  },
  {
    name: sign_45up_45landing7UdZ7g9PtpMeta?.name ?? "sign-up-landing",
    path: sign_45up_45landing7UdZ7g9PtpMeta?.path ?? "/sign-up-landing",
    meta: sign_45up_45landing7UdZ7g9PtpMeta || {},
    alias: sign_45up_45landing7UdZ7g9PtpMeta?.alias || [],
    redirect: sign_45up_45landing7UdZ7g9PtpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/sign-up-landing.vue").then(m => m.default || m)
  },
  {
    name: sign_45up_45old50MVA6siDhMeta?.name ?? "sign-up-old",
    path: sign_45up_45old50MVA6siDhMeta?.path ?? "/sign-up-old",
    meta: sign_45up_45old50MVA6siDhMeta || {},
    alias: sign_45up_45old50MVA6siDhMeta?.alias || [],
    redirect: sign_45up_45old50MVA6siDhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/sign-up-old.vue").then(m => m.default || m)
  },
  {
    name: sign_45upnkbH2bO3TLMeta?.name ?? "sign-up",
    path: sign_45upnkbH2bO3TLMeta?.path ?? "/sign-up",
    meta: sign_45upnkbH2bO3TLMeta || {},
    alias: sign_45upnkbH2bO3TLMeta?.alias || [],
    redirect: sign_45upnkbH2bO3TLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: signedout26UpwF1D9BMeta?.name ?? "signedout",
    path: signedout26UpwF1D9BMeta?.path ?? "/signedout",
    meta: signedout26UpwF1D9BMeta || {},
    alias: signedout26UpwF1D9BMeta?.alias || [],
    redirect: signedout26UpwF1D9BMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/signedout.vue").then(m => m.default || m)
  },
  {
    name: solutions4m8l3A6tcoMeta?.name ?? "solutions",
    path: solutions4m8l3A6tcoMeta?.path ?? "/solutions",
    meta: solutions4m8l3A6tcoMeta || {},
    alias: solutions4m8l3A6tcoMeta?.alias || [],
    redirect: solutions4m8l3A6tcoMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/solutions.vue").then(m => m.default || m)
  },
  {
    name: indexuZ4qYiWmW5Meta?.name ?? "speech",
    path: indexuZ4qYiWmW5Meta?.path ?? "/speech",
    meta: indexuZ4qYiWmW5Meta || {},
    alias: indexuZ4qYiWmW5Meta?.alias || [],
    redirect: indexuZ4qYiWmW5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/speech/index.vue").then(m => m.default || m)
  },
  {
    name: indexvuhyLJtivEMeta?.name ?? "support",
    path: indexvuhyLJtivEMeta?.path ?? "/support",
    meta: indexvuhyLJtivEMeta || {},
    alias: indexvuhyLJtivEMeta?.alias || [],
    redirect: indexvuhyLJtivEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexSgE4180AZJMeta?.name ?? "test",
    path: indexSgE4180AZJMeta?.path ?? "/test",
    meta: indexSgE4180AZJMeta || {},
    alias: indexSgE4180AZJMeta?.alias || [],
    redirect: indexSgE4180AZJMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/test/index.vue").then(m => m.default || m)
  },
  {
    name: landing1XTHTMnKNeUMeta?.name ?? "test-landing1",
    path: landing1XTHTMnKNeUMeta?.path ?? "/test/landing1",
    meta: landing1XTHTMnKNeUMeta || {},
    alias: landing1XTHTMnKNeUMeta?.alias || [],
    redirect: landing1XTHTMnKNeUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/test/landing1.vue").then(m => m.default || m)
  },
  {
    name: landing2uQxiUaoSS6Meta?.name ?? "test-landing2",
    path: landing2uQxiUaoSS6Meta?.path ?? "/test/landing2",
    meta: landing2uQxiUaoSS6Meta || {},
    alias: landing2uQxiUaoSS6Meta?.alias || [],
    redirect: landing2uQxiUaoSS6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/test/landing2.vue").then(m => m.default || m)
  },
  {
    name: indexCIdkAVQRxHMeta?.name ?? "tickets",
    path: indexCIdkAVQRxHMeta?.path ?? "/tickets",
    meta: indexCIdkAVQRxHMeta || {},
    alias: indexCIdkAVQRxHMeta?.alias || [],
    redirect: indexCIdkAVQRxHMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: testDSw6lsjKQSMeta?.name ?? "tickets-test",
    path: testDSw6lsjKQSMeta?.path ?? "/tickets/test",
    meta: testDSw6lsjKQSMeta || {},
    alias: testDSw6lsjKQSMeta?.alias || [],
    redirect: testDSw6lsjKQSMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/dash/pages/tickets/test.vue").then(m => m.default || m)
  }
]