import payload_plugin_bdP0uUilyl from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@0.4.0_@firebase+app-types@0.9.0_firebase-admin@11.11.0_firebase-functions@4.4.1__7yc2s676qfkph5tqd4mzwroosq/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.mjs";
import revive_payload_client_9SXhmzGN7b from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.4_eslint@8.51.0_typescript@5.2.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_qsO4WiWoUH from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.4_eslint@8.51.0_typescript@5.2.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xDWiPC049w from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.4_eslint@8.51.0_typescript@5.2.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_PKJ4NtAa9v from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.4_eslint@8.51.0_typescript@5.2.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_uYTU6Kj24W from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.2.2_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/dash/.nuxt/components.plugin.mjs";
import prefetch_client_PwsTStBAkJ from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.4_eslint@8.51.0_typescript@5.2.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/vercel/path0/apps/dash/.nuxt/floating-vue.mjs";
import plugin_client_reCAyYUs6K from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@0.4.0_@firebase+app-types@0.9.0_firebase-admin@11.11.0_firebase-functions@4.4.1__7yc2s676qfkph5tqd4mzwroosq/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import plugin_client_ZiMELiuGJn from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@0.4.0_@firebase+app-types@0.9.0_firebase-admin@11.11.0_firebase-functions@4.4.1__7yc2s676qfkph5tqd4mzwroosq/node_modules/nuxt-vuefire/dist/runtime/auth/plugin.client.mjs";
import templates_plugin_33a22dbe_VnitdbZqcl from "/vercel/path0/apps/dash/.nuxt/templates.plugin.33a22dbe.ejs";
import motion_yMFi967CD4 from "/vercel/path0/node_modules/.pnpm/@vueuse+motion@2.0.0_vue@3.3.4/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import formkitPlugin_pZqjah0RUG from "/vercel/path0/apps/dash/.nuxt/formkitPlugin.mjs";
import colors_jfwDM3qCD7 from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.13.0_jwt-decode@3.1.2_nuxt@3.7.4_vite@4.5.2_vue@3.3.4/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_STsxLw8EHl from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_5rkGJVJFrD from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.4_eslint@8.51.0_typescript@5.2.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import vercel_X9S0nty7R0 from "/vercel/path0/apps/dash/plugins/vercel.ts";
import _02_wetixError_lZDvArcedZ from "/vercel/path0/apps/dash/plugins/02.wetixError.ts";
import apexcharts_client_Dd5Ebxj7XB from "/vercel/path0/apps/dash/plugins/apexcharts.client.ts";
import dayjs_pGiXRjcsJO from "/vercel/path0/apps/dash/plugins/dayjs.ts";
import displayCurrency_guI5IqQ6cV from "/vercel/path0/apps/dash/plugins/displayCurrency.ts";
import firebase_client_zXNDa0wxFH from "/vercel/path0/apps/dash/plugins/firebase.client.ts";
import getImage_BBxTpbnDT4 from "/vercel/path0/apps/dash/plugins/getImage.ts";
import gravatar_H9hJYGpdrS from "/vercel/path0/apps/dash/plugins/gravatar.ts";
import notifications_Wskzy5ZO71 from "/vercel/path0/apps/dash/plugins/notifications.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/apps/dash/plugins/sentry.client.ts";
import unsplash_QvhLkKfy5n from "/vercel/path0/apps/dash/plugins/unsplash.ts";
import vue_gtag_client_CYt6toK02h from "/vercel/path0/apps/dash/plugins/vue-gtag.client.ts";
export default [
  payload_plugin_bdP0uUilyl,
  revive_payload_client_9SXhmzGN7b,
  unhead_qsO4WiWoUH,
  router_xDWiPC049w,
  payload_client_PKJ4NtAa9v,
  plugin_vue3_uYTU6Kj24W,
  components_plugin_KR1HBZs4kY,
  prefetch_client_PwsTStBAkJ,
  floating_vue_EIcJ7xiw0h,
  plugin_client_reCAyYUs6K,
  plugin_client_ZiMELiuGJn,
  templates_plugin_33a22dbe_VnitdbZqcl,
  motion_yMFi967CD4,
  formkitPlugin_pZqjah0RUG,
  colors_jfwDM3qCD7,
  plugin_client_STsxLw8EHl,
  chunk_reload_client_5rkGJVJFrD,
  vercel_X9S0nty7R0,
  _02_wetixError_lZDvArcedZ,
  apexcharts_client_Dd5Ebxj7XB,
  dayjs_pGiXRjcsJO,
  displayCurrency_guI5IqQ6cV,
  firebase_client_zXNDa0wxFH,
  getImage_BBxTpbnDT4,
  gravatar_H9hJYGpdrS,
  notifications_Wskzy5ZO71,
  sentry_client_shVUlIjFLk,
  unsplash_QvhLkKfy5n,
  vue_gtag_client_CYt6toK02h
]