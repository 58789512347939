// This is state for showing notifications to the user globally in the app.
// It works in conjunction with the plugins/notifications.ts plugin to make nuxtApp.$notify() available globally.

import { acceptHMRUpdate, defineStore } from 'pinia'

export const useNotificationState = defineStore('notificationState', {

  state: () => ({
    defaultTimeout: 3000,
    notifications: [],
    notificationHistory: []
  }),

  getters: {
    // tenant: (state) => state.tenantId,
    hasNotifications: state => state.notifications.length > 0
  },

  actions: {
    addNotification ({ type = '', timeout = this.defaultTimeout, message = '', title = '', persistent = false }) {
      // Generate a random notification ID
      const id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

      // Push notifications onto the array
      const newNotification = {
        id,
        type,
        show: true,
        persistent,
        timeout,
        message: message || '',
        title: title || ''
      }

      // Push onto notifications array
      this.notifications.push(newNotification)

      // Push notification on to history
      this.notificationHistory.push(newNotification)

      // If the notification is not persistent and has a timeout, automatically remove it after timeout.
      if (timeout && !persistent) {
        setTimeout(() => {
          // TODO: Bugfix for this in SSR..
          const nToRemove = useFind(this.notifications, { id: newNotification.id }) // Use find to find the ref.
          // console.log({ nToRemove })
          this.removeNotification(nToRemove)
        }, timeout)
      }
    },
    removeNotification (n) {
      console.log('Closing notification: ', n)

      // Pull the notification by ID
      const notificationsAfter = usePull(this.notifications, n)

      console.log({ notificationsAfter })
      // Patch state
      this.notifications = notificationsAfter
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationState, import.meta.hot))
}
