import { defineNuxtPlugin } from '#app'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime.js'
import advancedFormat from 'dayjs/plugin/relativeTime.js'
import isBetween from 'dayjs/plugin/isBetween'

export default defineNuxtPlugin((nuxtApp) => {
  dayjs.extend(relativeTime)
  dayjs.extend(advancedFormat)
  dayjs.extend(isBetween)
  nuxtApp.provide('dayjs', dayjs)
})

declare module '#app' {
    interface NuxtApp {
        $dayjs: any
    }
}
