import VueGtag, { trackRouter } from 'vue-gtag-next'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const { gtagMeasurementId, disabled } = config.public.analytics

  if (disabled) {
    console.log('Analytics disabled via config.public.analytics.disable flag')
    return
  }

  if (!gtagMeasurementId) {
    // throw createError('Analytics is enabled but no gtag measurement ID found.')
    console.error('Analytics is enabled but no gtag measurement ID found.')
  }

  // Inject vue-gtag
  console.log(`Initialising gtag with measurement ID ${gtagMeasurementId}`)
  nuxtApp.vueApp.use(VueGtag, {
    property: {
      id: gtagMeasurementId
    }
  })
  trackRouter(useRouter())
})
