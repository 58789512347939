import * as Sentry from '@sentry/vue'
import { Breadcrumb, User } from '@sentry/types'

export default defineNuxtPlugin((nuxtApp) => {
  // Get config from nuxtApp to use dynamic config
  const config = useRuntimeConfig()

  // Get vueApp instance
  const { vueApp } = nuxtApp

  // Get the vue router
  const router = useRouter()

  // Log if sentry is enabled but only in development
  if (config.public.sentryEnabled) {
    console.log('[WeTix Sentry Plugin] Sentry is enabled via SENTRY_ENABLED env var. ')
    console.log(`[WeTix Sentry Plugin] Sentry DSN: ${config.public.sentryDsn}`)
  } else {
    return console.log('[WeTix Sentry Plugin] Sentry is disabled via SENTRY_ENABLED env var.')
  }

  if (config.public.sentryEnabled && !config.public.sentryDsn) {
    return console.error('[WeTix Sentry Plugin] Failed to initialise sentry due to missing SENTRY_DSN config.public. Please set the SENTRY_DSN environment variable.')
  }

  try {
    // Initialise Sentry
    Sentry.init({
      app: [vueApp],
      dsn: config.public.sentryDsn,
      release: config.public.releaseSHA,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['localhost', `${config.public.SENTRY_TRACING_ORIGIN} `, /^\//]
        }),
        new Sentry.Replay() // Enable sentry replays
      ],
      logErrors: false, // Note that this doesn't seem to work with nuxt 3
      tracesSampleRate: config.public.sentryTracesSampleRate || 1, // Sentry recommends adjusting this value in production
      debug: config.public.sentryEnableDebug, // Enable debug mode
      environment: config.public.ENVIRONMENT, // Set environment
      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      // The following enables exeptions to be logged to console despite logErrors being set to false (preventing them from being passed to the default Vue err handler)
      beforeSend (event, hint) {
        // Check if it is an exception, and if so, log it.
        if (event.exception) {
          console.error(`[Exeption handled by WeTix Sentry Plugin]: (${hint.originalException})`, { event, hint })
        }
        // Continue sending to Sentry
        return event
      }
    })

    vueApp.mixin(Sentry.createTracingMixins({ trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] }))
    Sentry.attachErrorHandler(vueApp, { logErrors: false, attachProps: true, trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] })

    console.debug(`[WeTix Sentry Plugin] Sentry initialised. environment: ${config.public.ENVIRONMENT}, tracesSampleRate: ${config.public.SENTRY_TRACES_SAMPLE_RATE}, tracingOrigin: ${config.public.SENTRY_TRACING_ORIGIN} `)

    return {
      provide: {
        sentrySetContext: (n, context) => Sentry.setContext(n, context),
        sentrySetUser: (user: User) => Sentry.setUser(user),
        sentrySetTag: (tagName, value) => Sentry.setTag(tagName, value),
        sentryAddBreadcrumb: (breadcrumb: Breadcrumb) => Sentry.addBreadcrumb(breadcrumb)
      }
    }
  } catch (error) {
    console.error(`[WeTix Sentry Plugin] Error initialising sentry: ${error} `)
  }
})
